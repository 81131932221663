// import { useUser } from '@/lib/hooks';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Loading } from '@/components/shared';

import { useAuthContext } from '@/context/AuthContext';

const withAuth = (Component) => {
  return (props) => {
    const router = useRouter();
    const { user, loadingUser, errorUser } = useAuthContext();

    // if (loadingUser) {
    //   return <Loading />;
    // }
    // // システムエラーページに飛ばす必要あり
    // if (errorUser) {
    //   router.push('/systemerror');
    // }
    if (!user) {
      return <Redirect to="/sessionerror" />;
    } else {
      return <Component user={user} {...props} />;
    }
  };
};

export default withAuth;

// SignInのみに実装する認証（userがあったら初めて遷移という、リダイレクトが通常と逆の動作になるため）
export const SignInwithAuth = (Component) => {
  return (props) => {
    const router = useRouter();
    const { user, loadingUser, errorUser } = useAuthContext();

    // if (loadingUser) {
    //   return <Loading />;
    // }
    // // システムエラーページに飛ばす必要あり
    // if (errorUser) {
    //   router.push('/systemerror');
    // }

    if (user) {
      return <Redirect to="/general" />;
    } else {
      return <Component user={user} {...props} />;
    }
  };
};

export const Redirect = ({ to, ssr }) => {
  const router = useRouter();

  useEffect(() => {
    if (ssr) {
      window.location.pathname = to;
    } else {
      router.push(to);
    }
  }, []);

  return null;
};
